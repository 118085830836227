"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.Proxy__factory = void 0;
var ethers_1 = require("ethers");
var _abi = [
    {
        stateMutability: "payable",
        type: "fallback"
    },
    {
        stateMutability: "payable",
        type: "receive"
    },
];
var Proxy__factory = /** @class */ (function () {
    function Proxy__factory() {
    }
    Proxy__factory.createInterface = function () {
        return new ethers_1.utils.Interface(_abi);
    };
    Proxy__factory.connect = function (address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    };
    Proxy__factory.abi = _abi;
    return Proxy__factory;
}());
exports.Proxy__factory = Proxy__factory;
