"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.IERC1155ReceiverUpgradeable__factory = void 0;
var ethers_1 = require("ethers");
var _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address"
            },
            {
                internalType: "address",
                name: "from",
                type: "address"
            },
            {
                internalType: "uint256[]",
                name: "ids",
                type: "uint256[]"
            },
            {
                internalType: "uint256[]",
                name: "values",
                type: "uint256[]"
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes"
            },
        ],
        name: "onERC1155BatchReceived",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4"
            },
        ],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address"
            },
            {
                internalType: "address",
                name: "from",
                type: "address"
            },
            {
                internalType: "uint256",
                name: "id",
                type: "uint256"
            },
            {
                internalType: "uint256",
                name: "value",
                type: "uint256"
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes"
            },
        ],
        name: "onERC1155Received",
        outputs: [
            {
                internalType: "bytes4",
                name: "",
                type: "bytes4"
            },
        ],
        stateMutability: "nonpayable",
        type: "function"
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4"
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
];
var IERC1155ReceiverUpgradeable__factory = /** @class */ (function () {
    function IERC1155ReceiverUpgradeable__factory() {
    }
    IERC1155ReceiverUpgradeable__factory.createInterface = function () {
        return new ethers_1.utils.Interface(_abi);
    };
    IERC1155ReceiverUpgradeable__factory.connect = function (address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    };
    IERC1155ReceiverUpgradeable__factory.abi = _abi;
    return IERC1155ReceiverUpgradeable__factory;
}());
exports.IERC1155ReceiverUpgradeable__factory = IERC1155ReceiverUpgradeable__factory;
