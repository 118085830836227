"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
exports.__esModule = true;
exports.IERC165Upgradeable__factory = void 0;
var ethers_1 = require("ethers");
var _abi = [
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4"
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool"
            },
        ],
        stateMutability: "view",
        type: "function"
    },
];
var IERC165Upgradeable__factory = /** @class */ (function () {
    function IERC165Upgradeable__factory() {
    }
    IERC165Upgradeable__factory.createInterface = function () {
        return new ethers_1.utils.Interface(_abi);
    };
    IERC165Upgradeable__factory.connect = function (address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    };
    IERC165Upgradeable__factory.abi = _abi;
    return IERC165Upgradeable__factory;
}());
exports.IERC165Upgradeable__factory = IERC165Upgradeable__factory;
